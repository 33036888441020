<template>
<v-container class="shop-management-page">
    <div v-if="currentShop === null || isLoadingConnectedShops">
        <data-preparation-overlay></data-preparation-overlay>
    </div>
    <div v-else>
        <v-row>
            <v-col cols="6">
                <h1 class="text-h2">
                    <v-icon
                        aria-hidden="false"
                        dark
                        left
                        large
                        color="orange darken-2"
                    >
                    location_on
                    </v-icon>
                    Manage {{ partnerStoreLabel }}s
                </h1>
                <div class="title-small-text">
                    <small>You are currently connected to {{ connectedShops.length }} stores</small>
                </div>
            </v-col>
            <v-col cols="6">
                <connect-new-shop-widget 
                    type="button" 
                    label="Connect New Store"></connect-new-shop-widget>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="6">
                <v-text-field
                    v-if="currentShop != null && currentShop.type === 'destination'"
                    v-model="searchStr" 
                    outlined
                    label="Search Source Store by URL or Name"
                    append-icon="search"
                    v-on:keyup.enter="searchShops()"
                ></v-text-field>
                <v-text-field
                    v-else
                    v-model="searchStr" 
                    outlined
                    label="Search Destination Store by URL or Name"
                    append-icon="search"
                    v-on:keyup.enter="searchShops()"
                ></v-text-field>
            </v-col>
            <v-col cols="6"></v-col>
        </v-row>

        <v-row>
            <v-col cols="12">
                <v-data-table
                    :headers="headers"
                    :items="connectedShops"
                    :loading="(isLoading || currentShop === null)"
                    hide-default-footer
                    :items-per-page="25"
                    class="elevation-1"
                >
                    <template v-slot:no-data>
                        <div class="mt-1">
                            <v-card-text>
                                You are currently not connected to any
                                {{ partnerStoreLabel }}
                            </v-card-text>
                            <connect-new-shop-widget 
                                type="link" 
                                :label="'Connect a ' + partnerStoreLabel "></connect-new-shop-widget>
                        </div>
                    </template>
                    <template v-slot:item.platform="{ item }">
                        <div v-if="item.platform === 'woocommerce'">
                            <v-img 
                                max-height="25" 
                                max-width="25" 
                                src="../../../assets/images/woo_logo.png"
                                class="text-center"
                            ></v-img>   
                        </div>
                        <div v-else-if="item.platform === 'shopify'">
                            <v-img 
                                max-height="25" 
                                max-width="25" 
                                src="../../../assets/images/shopify_logo.png"
                            ></v-img>  
                        </div>
                        <div v-else>
                            {{ item.platform }}
                        </div>
                    </template>
                    <template v-slot:item.status="{ item }">
                        <div v-if="typeof(item.status) != 'undefined'">
                            {{ item.status }}
                        </div>
                        <div v-else>
                            <v-icon 
                                color="success" 
                                small
                                class="text-success mr-3"
                            >
                                lens
                            </v-icon>
                            <strong>Active</strong>
                        </div>
                    </template>
                    <template v-slot:item.label="{ item }">
                        <div>{{ item.store_domain }}</div>
                    </template>
                    <template v-slot:item.url="{ item }">
                        <a :href="item.store_domain" target="_blank">{{ item.store_domain }}</a>
                    </template>
                    <template v-slot:item.actions="{ item }">
                        <div>
                            <v-btn
                                @click="openProductPageWithShop(item.id)"
                                class="mr-3"
                                outlined
                                small
                            >
                                View Product
                            </v-btn>
                            <v-btn
                                @click="openDisconnectConfirmationDialog(item.connection_id)"
                                outlined
                                small
                            >
                                Disconnect
                            </v-btn>
                        </div>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
    </div>

    <v-overlay :value="showDisconnectConfirmation">
        <v-card
            light
            class="disconnect-confirmation-dialog"
        >
            <v-toolbar
                dark
            >
                <v-card-title
                    color="dark"
                >
                    <v-icon
                        color="red"
                        class="mr-5"
                    >
                        error_outline
                    </v-icon>
                    <div class="title-text">
                        <div>Store Disconnect Options</div>
                        <small>
                            Please choose carefully, there is no undo option.
                        </small>
                    </div>
                </v-card-title>
                <v-icon
                    class="dc-close"
                    @click="showDisconnectConfirmation = false"
                >
                    close
                </v-icon>
            </v-toolbar>
            <v-card-text
                class="overflow-hidden"
            >
                <h3>Disconnect Store and KEEP Synced Products</h3>
                <p>
                    Disconnects your store with the selected store. Any previously synced products on this destination store will be unsynced and WILL NOT BE DELETED. 
                </p>
                <v-btn
                    color="primary"
                    class="float-right"
                    @click="disconnectShop(true)"
                >
                    Disconnect & Keep
                </v-btn>
            </v-card-text>
            <div class="divline"></div>
            <v-card-text
                class="overflow-hidden"
            >
                <h3>Disconnect Store and DELETE Synced Products</h3>
                <p>
                     Disconnects your store with the selected store. Any previously synced products on this destination store will be unsynced and WILL BE DELETED. 
                </p>
                <v-btn
                    color="red"
                    class="float-right"
                    @click="disconnectShop(false)"
                >
                    Disconnect & DELETE
                </v-btn>
            </v-card-text>
        </v-card>
    </v-overlay>
</v-container>
</template>
<script>
import { mapState } from "vuex";
import { LOAD_CONNECTED_SHOPS } from "@/store/actions.type";

import connectNewShopWidget from "@/views/shop/components/ConnectNewShopWidget";
import DataPreparationOverlay from "@/views/components/DataPreparationOverlay";

export default {
    name: "ShopManagementPage",
    components: {
        connectNewShopWidget,
        DataPreparationOverlay,
    },
    data: function () {
        return {
            headers: [
                { text: "Platform", value: "platform", sortable: true },
                { text: "Status", value: "status", sortable: true },
                { text: "Label", value: "label", sortable: true },
                { text: "Url", value: "store_domain", sortable: true },
                { text: "Actions", value: "actions", sortable: true },
            ],
            searchStr: null,
            sortBy: null,
            pageNumber: 1,
            displayedShops: null,
            showDisconnectConfirmation: false,
            targetConnectionId: null,
        }
    },
    computed: {
        ...mapState("shop", ["connectedShops", "isLoading", "currentShop", "isLoadingConnectedShops"]),
        partnerStoreLabel() {
            if (this.currentShop != null && this.currentShop.type != "source") {
                return "Source store";
            } else {
                return "Destination store";
            }
        },
    },
    created: function() {
        this.$store.dispatch(`shop/init`);
    },
    methods: {
        openAction(connectedShop) {
            console.log(connectedShop);
            return;
        },
        searchShops() {
            if (this.searchStr.length > 3) {
                this.$store.dispatch(`shop/${LOAD_CONNECTED_SHOPS}`, { searchStr: this.searchStr })
            }
        },
        openDisconnectConfirmationDialog(cid) {
            this.targetConnectionId = cid;
            this.showDisconnectConfirmation = true;
        },
        disconnectShop(option) {
            if (this.targetConnectionId != null) {
                this.$store.dispatch(`shop/disconnectShop`, { connectionId: this.targetConnectionId, keepProduct: option });
            } else {
                alert('Error: Need to specific which store to disconnect with.');
            }
            this.showDisconnectConfirmation = false;
        },
        openProductPageWithShop(sid) {
            this.$router.replace({ 
                name: "ProductManagementPage",
                params: {
                    productPageEntryShopId: sid,
                } 
            });
        },
    },
}
</script>
<style lang="scss">
.shop-management-page {
    .v-input {
        .v-input__slot {
        background-color: #fff;
        }
    }
    .title-small-text {
        padding-left: 50px;
    }
    .search-bar {
        padding: 10px 20px;
        border: 1px solid #ccc;
        border-radius: 5px;
        width: 100%;
    }
    .store-connections-table {
        width: 100%;
        > thead {
            border-bottom: 1px solid #ccc;
        }
        .no-record-wrapper {
            text-align: center;
        }
    }
    .disconnect-confirmation-dialog {
        .title-text {
            line-height: 18px;
        }
        .divline {
            border-bottom: 1px solid #ccc;
            margin: auto 15px;
        }
        .dc-close {
            position: absolute;
            right: 15px;
        }
    }
}
</style>