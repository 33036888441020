<template>
    <div>
        <v-dialog v-model="dialog" width="700" min-height="500">
            <template v-slot:activator="{on, attrs}" v-if="type === 'button'" @click="resetDialog()">
                <v-btn
                    color="primary"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    class="connect-new-btn"
                    depressed
                >
                    <v-icon
                        left>
                        add_circle
                    </v-icon>
                    {{ label }}
                </v-btn>
            </template>
            <template v-slot:activator="{on, attrs}" v-else @click="resetDialog()">
                <v-card-text
                    v-bind="attrs"
                    v-on="on"
                    color="primary"
                    class="link-style"
                >
                    Connect a 
                    {{ partnerStoreLabel }}
                </v-card-text>
            </template>
            <v-card 
                v-if="choice === null && !showCompleted && !isLoading"
                class="connect-new-shop-widget"
            >
                <v-card-text class="text-center">
                    <svg width="60px" height="30px" viewBox="0 0 130 30">
                        <circle cx="28" cy="10" r="25" class="filled"/>
                        <line x1="51" y1="10" x2="80" y2="10" class="line"/>
                        <circle cx="103" cy="10" r="25" class="not-filled"/>
                    </svg>
                </v-card-text>
                <v-card-title class="choice-title-bar">
                    <v-card-text>
                        <h1 class="text-center">
                            How would you like to connect?
                        </h1>
                    </v-card-text>
                    <div
                        class="close-btn"
                        @click="dialog = false"
                    >
                        <v-icon>
                            close
                        </v-icon>
                    </div>
                </v-card-title>
                <v-card-text>
                    <v-row class="text-center">
                        <div class="choice-options ml-auto mr-auto">
                            <v-card
                                outlined
                                class="choice-box"
                                @click="setChoice('email')"
                            >
                                <div class="help-icon text-right">
                                    <v-icon
                                    >
                                        help_outline
                                    </v-icon>
                                </div>
                                <v-card-text class="text-center option-wrapper">
                                    <v-icon
                                        class="option-icon"
                                    >
                                        email
                                    </v-icon>
                                </v-card-text>
                                <v-card-text class="text-center">
                                    <div><strong>Invite Via Email</strong></div>
                                    <small>For New or Existing Source Stores</small>
                                </v-card-text>
                            </v-card>
                            <v-card col="6"
                                outlined
                                class="choice-box"
                                @click="setChoice('key')"
                            >
                                <div class="help-icon text-right">
                                    <v-icon>
                                        help_outline
                                    </v-icon>
                                </div>
                                <v-card-text class="text-center option-wrapper">
                                    <v-icon
                                        class="option-icon"
                                    >
                                        vpn_key
                                    </v-icon>
                                </v-card-text>
                                <v-card-text class="text-center">
                                    <div><strong>Instant Connect via Store Key</strong></div>
                                    <small>For Existing Source Stores</small>
                                </v-card-text>
                            </v-card>
                        </div>
                    </v-row>
                </v-card-text>
            </v-card>
            <v-card 
                v-else-if="choice === 'email' && !showCompleted && !sendingEmailInvoice"
                class="form-action-wrapper"
            >
                <v-card-text class="text-center">
                    <svg width="60px" height="30px" viewBox="0 0 130 30">
                        <circle cx="28" cy="10" r="25" class="filled"/>
                        <line x1="51" y1="10" x2="80" y2="10" class="line"/>
                        <circle cx="103" cy="10" r="25" class="filled"/>
                    </svg>
                </v-card-text>
                <v-card-title>
                    <v-card-text>
                        <h1 class="text-center">Invite via E-mail Address</h1>
                    </v-card-text>
                    <div class="back-btn"
                        @click="resetDialog()"
                    >
                        <v-icon>
                            arrow_backward
                        </v-icon>
                    </div>
                    <div
                        class="close-btn"
                        @click="dialog = false"
                    >
                        <v-icon>
                            close
                        </v-icon>
                    </div>
                </v-card-title>
                <v-card-text>
                    Sending an email invitation to 
                    {{ partnerStoreLabel}}
                    that you want to connect to. This email will include your Syncio Key & installation instructions.
                </v-card-text>
                <v-card-text>
                    <div>
                        <v-text-field
                            v-model="inviteToEmail"
                            label="E-mail Address"
                            outlined
                            :messages="'Enter ' + partnerStoreLabel + ' e-mail address'"
                            
                        ></v-text-field>
                    </div>
                </v-card-text>
                <v-card-text>
                    <div class="text-center">
                        <v-btn
                            class="primary btn"
                            @click="sendEmailInvite()"
                        >
                            Send E-mail Invite
                            <v-icon
                                right
                            >
                                arrow_forward
                            </v-icon>
                        </v-btn>
                    </div>
                </v-card-text>
            </v-card>

            <v-card 
                v-else-if="choice === 'key' && !showCompleted && !isConnectingShop"
                class="form-action-wrapper key-connect-block"
            >
                <notification-component
                    position="store-connection-alert"
                ></notification-component>
                <v-card-text class="text-center">
                    <svg width="60px" height="30px" viewBox="0 0 130 30">
                        <circle cx="28" cy="10" r="25" class="filled"/>
                        <line x1="51" y1="10" x2="80" y2="10" class="line"/>
                        <circle cx="103" cy="10" r="25" class="filled"/>
                    </svg>
                </v-card-text>
                <v-card-title>
                    <v-card-text>
                        <h1 class="text-center">Enter Store Key</h1>
                    </v-card-text>
                    <div class="back-btn"
                        @click="resetDialog()"
                    >
                        <v-icon>
                            arrow_backward
                        </v-icon>
                    </div>
                    <div
                        class="close-btn"
                        @click="dialog = false"
                    >
                        <v-icon>
                            close
                        </v-icon>
                    </div>
                </v-card-title>
                <v-card-text>
                    Enter the Syncio Key for the 
                    {{ partnerStoreLabel }}
                    that you want to connect to below. Once connected you can immediately start syncing products from that store.
                </v-card-text>
                <v-card-text>
                    <div>
                        <v-text-field
                            v-model="targetStoreIdentifier"
                            label="Syncio Key"
                            outlined
                            :messages="'Enter ' + partnerStoreLabel + '\'s Syncio Key'"
                        ></v-text-field>
                    </div>
                </v-card-text>
                <v-card-text>
                    <div class="text-center">
                        <v-btn
                            class="primary btn"
                            @click="connectStoreWithKey()"
                        >
                            Connect 
                            {{ partnerStoreLabel }}
                            <v-icon
                                dark
                                right
                            >
                            arrow_forward
                            </v-icon>   
                        </v-btn>
                    </div>
                </v-card-text>
            </v-card>

            <v-card v-else-if="choice === 'email' && !showCompleted && sendingEmailInvoice"
                class="form-action-wrapper"
            >
                <v-card-text
                    class="text-center"
                >
                    <v-img class="rotate ma-auto mb-5" src="~@/assets/images/logo.svg" height="98px" width="75px" contain></v-img>
                    <h2>Sending Invite Email</h2>
                </v-card-text>
            </v-card>

            <v-card v-else-if="choice === 'key' && !showCompleted && isConnectingShop"
                class="form-action-wrapper"
            >
                <v-card-text
                    class="text-center"
                >
                    <v-img class="rotate ma-auto mb-5" src="~@/assets/images/logo.svg" height="98px" width="75px" contain></v-img>
                    <h2>Connecting Store</h2>
                </v-card-text>
            </v-card>

            <v-card v-else-if="choice === 'email' && showCompleted && !isLoading"
                class="form-action-wrapper"
            >
                <v-card-title>
                    <v-card-text>
                        <h1 class="success-h1 text-center text-h2">
                            <v-icon
                                dark
                                center
                                color="success"
                                large
                                class="success-icon"
                            >
                                check_circle
                            </v-icon>
                            <div>Success</div>
                        </h1>
                    </v-card-text>
                </v-card-title>
                <v-card-text class="text-center">
                    Your invitation email was sent successfully.
                </v-card-text>
            </v-card>
            <v-card 
                v-else-if="choice === 'key' && showCompleted && !isConnectingShop"
                class="form-action-wrapper"
            >
                <v-card-title class="text-center">
                    <h1 class="success-h1 text-center text-h2">
                        <v-icon
                            dark
                            center
                            color="success"
                            large
                            class="success-icon"
                        >
                            check_circle
                        </v-icon>
                        <div>Success</div>
                    </h1>

                    <v-card-text class="center">
                        <p>
                            You are now connected with 
                            <strong>{{ newlyConnectedShop.store_domain }}</strong>
                        </p>
                        <v-btn
                            class="primary btn"
                            @click="redirectProductPageWithShop(newlyConnectedShop.id)"
                        >
                            Start Syncing Products
                        </v-btn>
                    </v-card-text>
                </v-card-title>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import { SET_NEWLY_CONNECTED_SHOP } from "@/store/mutations.type";
import { ADD_NOTIFICATION, LOAD_CONNECTED_SHOPS } from "@/store/actions.type";
import {required, email }  from "vuelidate/lib/validators";
import ShopApiService from "@/common/api/shop.service";
import NotificationComponent from "@/views/components/NotificationComponent";

export default {
    name: "ConnectNewShopWidget",
    props: ['type', 'label'],
    data: function() {
        return {
            dialog: false,
            choice: null,
            inputStr: null,
            showCompleted: false,
            inviteToEmail: null,
            targetStoreIdentifier: null,
            sendingEmailInvoice: false,
            isConnectingShop: false,
        };
    },
    validations: {
        inviteToEmail: { required, email },
        targetStoreIdentifier: { required }
    },
    components: {
        NotificationComponent,
    },
    computed: {
        ...mapState("shop", [ "isLoading", "currentShop", "newlyConnectedShop" ]),
        partnerStoreLabel() {
            if (this.currentShop != null && this.currentShop.type != "source") {
                return "Source store";
            } else {
                return "Destination store";
            }
        },
    },
    watch: {
        dialog(val) {
            !val && this.resetDialog();
        }
    },
    methods: {
        setChoice(value) {
            this.choice = value;
        },
        sendEmailInvite() {
            this.sendingEmailInvoice = true;
            new Promise((resolve) => {
                ShopApiService.inviteShop(this.inviteToEmail, this.currentShop.id)
                    .then(({ data }) => {
                        if (data.success) {
                            alert('email send');
                        } else {
                            alert('email not send')
                        }
                        this.sendingEmailInvoice = false;
                        this.showCompleted  = true;
                        resolve();
                    })
                    .catch((error) => {
                        console.log(error);
                        this.sendingEmailInvoice = false;
                    });
            });
        },
        connectStoreWithKey() {
            this.isConnectingShop = true;
            this.$store.commit(`shop/${SET_NEWLY_CONNECTED_SHOP}`, null);
            new Promise((resolve) => {
                ShopApiService.connectShop(this.currentShop.id, this.targetStoreIdentifier)
                    .then(({ data }) => {
                        if (data.success) {
                            this.$store.commit(`shop/${SET_NEWLY_CONNECTED_SHOP}`, data.target_store);
                            this.$store.dispatch(`shop/${LOAD_CONNECTED_SHOPS}`, { searchStr: null });
                        } else {
                            this.$stoew.dispatch(`notification/${ADD_NOTIFICATION}`, {
                                notification: {
                                    id: 'N' + (Math.floor(Math.random() * 100000000)),
                                    position: "store-connection-alert",
                                    type: "error",
                                    body: "Store connection unsuccessful!",
                                    state: 0,
                                    length: 8000, // seconds
                                    data: null,
                                },
                            }, {root:true});
                            this.isConnectingShop = false;
                        }
                        resolve();
                    })
                    .catch(({ data }) => {
                        console.log(data.errors);
                        let errorMsg = "Failed to connect store with " + this.targetStoreIdentifier;
                        if (typeof (data.errors) != 'undefined' && typeof(data.errors.target_store_identifier) != 'undefined') {
                            errorMsg = data.errors.target_store_identifier[0];
                        }
                        this.$store.dispatch(`notification/${ADD_NOTIFICATION}`, {
                            notification: {
                                id: 'N' + (Math.floor(Math.random() * 100000000)),
                                position: "store-connection-alert",
                                type: "error",
                                body: errorMsg,
                                state: 0,
                                length: 6000, // seconds
                                data: null,
                            },
                        }, {root:true});
                        this.isConnectingShop = false;
                    });
            });
        },
        resetDialog() {
            this.choice = null;
            this.inputStr = null;
            this.showCompleted = false;
            this.inviteToEmail = null;
        },
        closeDialog() {
            this.resetDialog();
            this.dialogLoading = false;
            this.dialog = false;
        },
        checkEmailError() {
            const emailErrors = [];
            if (!this.$v.inviteToEmail.$dirty) return emailErrors
            !this.$v.inviteToEmail.email && emailErrors.push("Must be valid e-mail")
            !this.$v.inviteToEmail.required && emailErrors.push("E-mail is required")
            return emailErrors
        },
        redirectProductPageWithShop(sid) {
            this.$router.replace({ 
                name: "ProductManagementPage",
                params: {
                    productPageEntryShopId: sid,
                } 
            });
        },
    }
}
</script>
<style lang="scss">
.connect-new-btn {
    padding: 20px 40px !important;
    float: right;
    margin-top: 10px;
}
.connect-new-shop-widget {
    padding: 40px 20px 60px;
    .choice-title-bar {
        min-height: 70px;
        .close-btn {
            position: absolute;
            right: 18px;
            top: 10px;
        }
    }
    .choice-options {
        .choice-box {
            padding: 20px;
            box-sizing: border-box;
            width: 280px;
            margin: 4px;
            float: left
        }
        .option-wrapper {
            .option-icon {
                font-size: 60px;
            }
        }
    }
}
.form-action-wrapper {
    padding: 40px;
    .btn {
        padding: 20px 40px !important;
    }
    .close-btn {
        position: absolute;
        right: 18px;
        top: 10px;
    }
    .back-btn {
        position: absolute;
        left: 18px;
        top: 10px;
    }
    &.key-connect-block {
        position: relative;
    }
}
.link-style {
    text-decoration: underline;
}
.success-h1 {
    margin: auto;
    .success-icon {
        font-size: 40px;
    }
}
.filled{
  fill: #fcb058;
  stroke-width: 5;
  stroke: #f57c00;
}
.not-filled{
  fill: none;
  stroke-width: 5;
  stroke: #f57c00;
}
.line{
  stroke-width: 5;
  stroke:#f57c00
}

.notification-component-wrapper {
    &.store-connection-alert {
        position: absolute;
        top: 108px;
        left: 60px;
        width: 100%;
        max-width: 500px;
        z-index: 999;
    }
}

.rotate {
  animation: rotation 3s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-359deg);
  }
}
</style>